import React, { FormEvent, useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";

export const pageQuery = graphql`
  query PartnersSuccessPage {
    site {
      siteMetadata {
        pages {
          partners {
            title
            description
          }
        }
      }
    }
  }
`;

interface PartnersSuccessPageProps extends PageProps {
  data: Queries.PartnersSuccessPageQuery;
}

export default function DemoSuccessPage(props: PartnersSuccessPageProps) {
  const { data } = props;
  const { title, description } = data.site?.siteMetadata?.pages?.partners as Queries.SiteSiteMetadataPageSuccessPartners;

  const seo = {
    title: title as string,
    description: description as string,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection
        title="¡Gracias por tu interés!"
        subtitle={
          <>
            Esto puede ser el comercio de una gran relación.
          </>
        }
      />
    </Layout>
  );
}
